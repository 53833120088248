<template>
  <form class="contact-form" @submit.prevent="sendEmail">
    <input name="to_name" type="hidden" value="Bindustry">
    <input name="to_host" type="hidden" value="contacto@bindustry.com.mx">
    <div class="form-group">
        <input  name="from_name" type="text" class="form-control" id="nombre" required="required" placeholder="Nombre Completo">
    </div>
    <div class="form-group">
        <input name="reply_to" type="email" class="form-control" id="correo" required="required" placeholder="Correo Electrónico">
    </div>
    <textarea name="message" type="text" class="form-control" id="asunto" rows="5" required="required" placeholder="¿Cómo podemos ayudarte?"></textarea>
    <input id="sendBtn" type="submit" value="Enviar" class="btn btn-red-border submit-btn">
  </form>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  methods: {
    sendEmail: (e) => {
      const btn = document.getElementById("sendBtn");
      btn.value = "Enviando...";
      emailjs.sendForm('service_q2zlk5g', 'template_lnf44bf', e.target, 'user_iv7NEVHX3p6AihGpfuzp6')
        .then((result) => {
            btn.value = "Enviado";
            console.log('¡Mensaje Enviado!', result.status, result.text);
        }, (error) => {
            btn.value = "Intente de nuevo";
            console.log('Error el enviar mensaje...', error);
        });
    }
  }
}
</script>