<template>
  <div id="app">
    <!--
    <nav id="mainNavigation" class="navbar navbar-fixed-top" role="navigation">
            <div class="container">
                
                <div class="navbar-header">
                    
                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#main-nav-collapse" aria-expanded="false">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar">-</span>
                        <span class="icon-bar">-</span>
                        <span class="icon-bar">-</span>
                    </button>
                   
                    
                    <div class="navbar-brand">
                        <span class="sr-only">Bindustry</span>
                        <a href="index.html">
                            <img src="./img/main_logo.png" class="img-responsive center-block" alt="logo">
                        </a>
                    </div>
                    

                </div>
                
                <div class="collapse navbar-collapse" id="main-nav-collapse">
                    <ul class="nav navbar-nav navbar-right text-uppercase">
                        <li class="active">
                            <a data-scroll href="#header">Inicio</a>
                        </li>
                        <li>
                            <a data-scroll href="#about">Acerca de</a>
                        </li>
                        <li>
                            <a data-scroll href="#services">Servicios</a>
                        </li>
                        <li>
                            <a data-scroll href="#contact">Contacto</a>
                        </li>
                    </ul>
                </div>
                
            </div>
    </nav>
        -->
    <b-navbar toggleable="lg" type="dark" style="background-color: #EE8175;" sticky="true" class="mx-auto nav-bin" >
      <div class="container">
        <b-navbar-brand href="#header">
          <a href="#header">
              <img src="./img/main_logo.png" class="img-responsive center-block" alt="logo">
          </a>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse class="nav-font" id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item class="nav-bin-item" href="#header" active>INICIO</b-nav-item>
            <b-nav-item class="nav-bin-item" href="#about">NOSOTROS</b-nav-item>
            <b-nav-item class="nav-bin-item" href="#services">SERVICIOS</b-nav-item>
            <b-nav-item class="nav-bin-item" href="#contact">CONTACTO</b-nav-item>
          </b-navbar-nav>
          

        </b-collapse>
      </div>
    </b-navbar>

    <div id="home-page">


        
        <header id="header" class="header-wrapper home-parallax home-fade">
            <div class="color-overlay"></div>
            <div class="header-wrapper-inner">
                <div class="container">

                    <div class="intro">
                        <h1>Publicidad y Marketing</h1>
                        <br/>
                        <a href="#services" class="btn btn-red-border">Nuestros Servicios</a>
                    </div>

                </div>
            </div>
        </header>
        

        <section id="about" class="dark-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="section-title">
                            <h2>NOSOTROS</h2>
                        </div> 
                    </div>
                </div>

                <div class="about-info">
                    <div class="row">
                        <div class="col-md-4">
                            <img src="./img/marketing.jpg" alt="marketing" class="img-responsive center-block">
                        </div>
                        <div class="col-md-8">
                            <h2>PUBLICIDAD</h2>
                            <p>
                                Ofrecemos todos los servicios de promotoría, aplicación de encuestas y demás herramientas necesarias para lograr el posicionamiento de tu marca en tu mercado meta.
                            </p>
                            <p>
                                <span>"Somos una empresa innovadora y creativa que impregna dinámica a tu organización, evolucionando junto a las tendencias del mercado"</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="about-content">
                            <i><b-icon-flag-fill class="about-icon center-block"/></i>
                            <h3 class="about-heading">MISIÓN</h3>
                            <p class="about-description">
                                Brindar el mejor servicio, con la mejor atención personalizada y confiable del sector, generando una evolución organizacional con cada cliente, manteniendo siempre la calidad e innovación.
                            </p> 
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="about-content">
                            <i><b-icon-eye-fill class="about-icon center-block"/></i>
                            <h3 class="about-heading">VISIÓN</h3>
                            <p class="about-description">
                                Generar el liderazgo empresarial número uno del país en el sector, mediante una coordinación entre nuestro equipo de trabajo, clientes y proveedores, formando una sociedad responsable e inclusiva con su entorno.
                            </p> 
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="about-content">
                            <i><b-icon-award-fill class="about-icon center-block"/></i>
                            <h3 class="about-heading">¿QUÉ NOS CARACTERIZA?</h3>
                            <p class="about-description">
                                Innovación, creatividad, comunicación, especialización, responsabilidad y trabajo en equipo son todas las cosas que nos definen y nos hacen únicos.
                            </p> 
                        </div> 
                    </div>
                </div>
            </div>
        </section>
        
        
        <section id="services" class="light-gray-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="section-title">
                            <h2>Nuestros Servicios</h2>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-4 col-sm-6">
                        <div class="service-content">
                            <i class="center-block service-icon"><b-icon-telephone-fill/></i>
                            <br/><br/>
                            <h3>Servicio al Cliente</h3>
                            <p class="text-center">Asesoramos, gestionamos y desarrollamos habilidades y competencias para lograr la satisfacción del cliente, 
                                una experiencia de compra única y de mayor ventaja competitiva.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <div class="service-content">
                            <i class="center-block"><b-icon-badge-ad-fill/></i>
                            <br/><br/>
                            <h3>Publicidad</h3>
                            <p class="text-center">Todos los servicios de promotoría, aplicación de encuestas y demás herramientas necesarias para lograr el posicionamiento de tu marca en tu mercado meta.</p>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <div class="service-content">
                            <i class="center-block"><b-icon-bar-chart-line-fill/></i>
                            <br/><br/>
                            <h3>Ventas</h3>
                            <p class="text-center">Estrategias de acuerdo a las necesidades de tu organización para lograr resultados directos en tu fuerza de ventas, desde las ventas de campo (operativo)
                                  hasta lo adinistrativo.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </section> 
        

        <section class="call-to-action text-capitalize">
            <div class="container-fluid container">
                <div class="row">
                    <div class="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1">
                        <div class="row">
                            <div class="col-md-8 col-sm-9">
                                <h3>¿Estás listo para sacar el máximo potencial de tu empresa con nosotros?</h3>
                            </div>
                            <div class="col-md-4 col-sm-3">
                                <a href="#contact" class="btn btn-white-border">
                                    Cotiza Ahora
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 

        <section id="contact">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <h3>Ubícanos</h3>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d329.9638569566035!2d-103.3494826152792!3d20.66490421535906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b2029bcfa36d%3A0xf1b55ade6466759e!2sAv.%2016%20de%20Septiembre%20720%2C%20Centro%2C%2044180%20Guadalajara%2C%20Jal.!5e0!3m2!1ses!2smx!4v1657913829379!5m2!1ses!2smx"
                        width="350" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                    <div class="col-md-4">
                        <h3>Contacto</h3>
                        <FormEmailJs/>
                      
                    </div>
                    <div class="col-md-4">
                        <h3>Datos de Contacto</h3>

                        <p>
                            Av. 16 de Septiembre 730, Centro, 44180 Guadalajara, Jal.
                        </p>

                        <p>
                            <strong>Teléfono: </strong>+52 33 3026 7378
                        </p>

                        <p>
                            <strong>Correo: </strong>contacto@bindustry.com
                        </p>
                        
                    </div>
                </div>
            </div>
        </section>

        <footer class="text-uppercase">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-sm-6">
                        <p>&copy; copyright <a class="copy-bin">BINDUSTRY</a> 2021</p>
                    </div>
                </div>
            </div>
        </footer>


    </div>
  </div>
</template>

<script>
import FormEmailJs from './components/FormEmailJs.vue'

export default {
  name: 'App',
  components: {
      FormEmailJs
  }
}
</script>

